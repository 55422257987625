<template>
  <div>
    <card :title="true" :titleClass="'mb-4'">
      <template v-slot:title>
        <div class="flex items-center" style="width: 50%;">
          <back-link :class="'mr-3'" />
          <h4 class="mb-0">New class</h4>
        </div>
        <copy-link
          title="Class link:"
          :value="'https://newtonteach.me/classes/' + newClass.id"
          :class="'flex-grow'"
        />
      </template>

      <form @submit.prevent="createClass">
        <div class="flex items-center justify-end mb-4">
          <router-link
            :to="{ name: 'classes' }"
            class="fz-normal primary_lightest mr-3"
          >
            Cancel
          </router-link>
          <btn
            type="submit"
            :value="'Save Changes'"
            :class="'fz-normal secondary_dark_bg text-white hover:text-white btn-small'"
          />
        </div>

        <form-group
          v-model="newClass.name"
          id="class_name"
          label="Class name"
          error="Class name field is required"
          title="Class name"
          required="true"
          class="mb-5"
        />

        <p class="gray-color fz-normal mb-2">Subject</p>
        <dropdown
          v-model="newClass.subject"
          :options="subjects"
          placeholder="Subject"
        />

        <p class="gray-color fz-normal mb-2">Grade</p>
        <dropdown
          v-model="newClass.grade"
          :options="grades"
          placeholder="Grade"
        />

        <h5 class="gray-color font-normal mb-1">Schedule</h5>
        <p class="gray-color fz-normal mb-4">
          if you select a day and time for your class, then it will appear on
          the schedule of every student in your class list.
        </p>

        <div
          class="newton-form-group flex flex-col-reverse color-primary-lighter-bg rounded-2xl color-primary-darkest fz-normal leading-none relative mb-3"
        >
          <a
            href="javascript:void(0)"
            class="w-full h-full flex items-center justify-between px-3 gray-color"
            @click.prevent="$modal.show('vote-feature')"
          >
            <span class="gray-color">Class Schedule</span>
            <img src="/img/icons/add.svg" alt="Add" />
          </a>
        </div>

        <v-modal
          name="vote-feature"
          :draggable="true"
          template="vote-feature"
        />

        <!-- <div
            class="flex items-center mb-1"
            v-for="(item, index) in newClass.schedule"
            :key="index"
          >
            <span class="small-text fw-semibold primary_lighter mr-2">
              Every {{ item.day }} {{ item.time }}
            </span>
            <button type="button" class="flex border-0 bg-transparent p-0">
              <img src="/img/icons/close.svg" alt="Remove" />
            </button>
          </div>

          <v-modal
            name="class-schedule"
            :draggable="true"
            template="class-schedule"
            width="350"
          /> -->

        <!-- <h5 class="fw-normal gray-color font-normal mt-4 mb-1">
            Student statistics
          </h5>
          <p class="fz-normal gray-color mb-4">
            Take your lessons into account in your student's overall statistics?
          </p>

          <checkbox
            type="radio"
            v-model="newClass.student_statistics"
            val="1"
            label="Yes"
            checked="checked"
            class="mb-2"
          />
          <checkbox
            type="radio"
            v-model="newClass.student_statistics"
            val="0"
            label="No"
          /> -->

        <h5 class="fw-normal gray-color font-normal mt-4 mb-1">
          Choose Icon
        </h5>
        <p class="fz-normal gray-color mb-4">
          You can chose special icon for your class. Otherwise we will set
          standart subject icon.
        </p>

        <div class="flex items-center gap-2">
          <label
            class="check-school-avatar relative"
            v-for="(avatar, index) in avatars"
            :key="index"
          >
            <input
              type="radio"
              v-model="newClass.avatar"
              name="class_avatar"
              :value="avatar"
              onMouseDown="this.isChecked=this.checked;"
              onClick="this.checked=!this.isChecked;"
              class="absolute w-full h-full opacity-0 cursor-pointer"
              @change="
                imagePreview = ''
                showPreview = false
              "
            />
            <img
              :src="'/img/icons_admin/classes_avatar/' + avatar"
              class="school-avatar"
            />
          </label>
          <img
            :src="imagePreview"
            v-show="showPreview"
            class="school-avatar active"
          />
          <input
            type="file"
            id="class-avatar"
            class="hidden"
            accept="image/*"
            ref="file"
            @change="handleFileUpload()"
          />
          <a
            href="javascript:void(0)"
            class="primary-lightest fw-semibold fz-normal"
            @click.prevent="$refs.file.click()"
          >
            Upload your photo
          </a>

          <a
            href="javascript:void(0)"
            class="gray-color fz-normal"
            @click.prevent="
              imagePreview = ''
              showPreview = false
              form.class_avatar = ''
            "
            v-show="showPreview"
          >
            Remove Photo
          </a>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
// import userLink from '@/components/user/UserLink'
import backLink from '@/components/elements/BackLink'
import checkbox from '@/components/form/Checkbox'
import dropdown from '@/components/form/Dropdown'

export default {
  name: 'create-new-class',
  data: () => ({
    canBeShown: false,
    avatars: [
      '1.png',
      '2.png',
      '3.png',
      '4.png',
      '5.png',
      '6.png',
      '7.png',
      '8.png',
    ],
    file: '',
    showPreview: false,
    imagePreview: '',
    subjects: [
      {
        id: 1,
        value: 'Geometry',
      },
      {
        id: 2,
        value: 'Algebra',
      },
      {
        id: 3,
        value: 'Astronomy',
      },
      {
        id: 4,
        value: 'History',
      },
      {
        id: 5,
        value: 'Physics',
      },
    ],
    grades: [
      {
        id: 1,
        value: 'PreK',
      },
      {
        id: 2,
        value: 'K',
      },
      {
        id: 3,
        value: '1st',
      },
      {
        id: 4,
        value: '2nd',
      },
      {
        id: 5,
        value: '3rd',
      },
      {
        id: 6,
        value: '4th',
      },
      {
        id: 7,
        value: '5th',
      },
      {
        id: 8,
        value: '6th',
      },
      {
        id: 9,
        value: '7th',
      },
      {
        id: 10,
        value: '8th',
      },
      {
        id: 11,
        value: '9th',
      },
      {
        id: 12,
        value: '10th',
      },
      {
        id: 13,
        value: '11th',
      },
      {
        id: 14,
        value: '12th',
      },
    ],
    newClass: {
      id: '',
      name: '',
      subject: '',
      grade: '',
      // student_statistics: 1,
      avatar: '',
      students: [],
    },
    tmpStudentsId: [],
  }),
  components: {
    // userLink,
    backLink,
    checkbox,
    dropdown,
  },
  computed: {
    students() {
      return this.$store.getters.GET_STUDENTS.filter((item) =>
        this.$store.getters.GET_TMP_STUDENTS_ID.includes(item.id),
      )
    },
  },
  mounted: function () {
    this.newClass.id = Date.now()
    if (!this.$store.dispatch('students_request')) {
      this.$store.dispatch('students_request')
    }
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0]
      let reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          this.showPreview = true
          this.imagePreview = reader.result
          this.form.class_avatar = this.file['name']
        }.bind(this),
        false,
      )
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file)
          let radio = document.querySelectorAll('[name="class_avatar"]')
          radio.forEach((item) => {
            item.checked = false
          })
        }
      }
    },
    createClass() {
      this.newClass.students = this.$store.getters.GET_TMP_STUDENTS_ID
      this.$store.commit('SET_NEW_CLASS', this.newClass)
      this.$store.commit('SET_TMP_STUDENTS_ID', [])
      this.$router.push({ name: 'classes' })
    },
  },
}
</script>
