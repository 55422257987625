<template>
	<label class="new-label">
	<input
	:type="type"
	:value="val"
	:required="required"
	:key="val"
	v-model="model"
	ref="input"
	>
	<span :class="'new-checkbox-radio new-primary new-'+type"></span>
	<span class="new-checkbox-radio-text fz-normal gray-color">{{label}}</span>
</label>
</template>

<script>
	export default {
		props: [
			'value',
			'val',
			'type',
			'label', 
			'checked', 
			'required'
		],
		computed: {
			model: {
			  get() { return this.value },
			  set(newValue) { this.$emit('input', newValue) }
			},
		},
		mounted: function(){
			if(this.checked){
				this.$refs.input.checked !=0;
			}
		}
	}
</script>