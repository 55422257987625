<template>
  <div
    class="new-dropdown newton-form-group color-primary-lighter-bg rounded-2xl relative mb-5 outline-0"
    :class="{
      'is-active': isOpen,
      'is-selected': (multi && value.length) || (value != '' && value != null),
    }"
    @keydown.esc="isOpen = false"
    tabindex="-1"
    v-click-outside="hide"
  >
    <button
      type="button"
      class="p-0 bg-transparent w-full h-full color-primary-darkest fz-normal leading-none rounded-2xl relative border-0 outline-0"
      @click="toggleDropdown()"
    >
      <span class="new-dropdown-label absolute">{{ placeholder }}</span>
      <span
        class="new-dropdown-value absolute color_primary_light text-nowrap w-full overflow-hidden text-left pr-5"
        v-if="multi"
      >
        <template v-for="(item, index) in value">
          <span :key="index">{{ item }}</span>
          <span
            :key="index"
            v-if="value.length > 1 && value.length != index + 1"
          >
            ,
          </span>
        </template>
      </span>
      <span
        class="new-dropdown-value absolute color_primary_light text-nowrap w-full overflow-hidden text-left pr-5"
        v-else
        v-text="value"
      ></span>
      <span class="new-dropdown-arrow absolute top-50">
        <img src="/img/icons_admin/arrow-down.svg" alt="Down" />
      </span>
    </button>

    <transition name="dropdown" v-if="multi">
      <div
        class="new-dropdown-list absolute w-full left-0 top-full rounded-2xl px-4 bg-white"
        v-if="isOpen || options.length"
      >
        <div class="p-0 py-1 m-0" v-for="(item, index) in options" :key="index">
          <label class="new-label py-2">
            <input
              :key="item.id"
              type="checkbox"
              :value="item.value"
              v-model="model"
              onMouseDown="this.isChecked=this.checked;"
              onClick="this.checked=!this.isChecked;"
            />
            <span class="new-checkbox-radio new-primary new-checkbox"></span>
            <span class="new-checkbox-radio-text fz-normal gray-color">
              {{ item.value }}
            </span>
          </label>
        </div>
        <div class="py-3 fz-normal gray-color" v-if="!options.length">
          <span
            class="new-checkbox-radio-text fz-normal gray-color text-opacity-70"
          >
            Nothing :((
          </span>
        </div>
      </div>
    </transition>

    <transition name="dropdown" v-else>
      <div
        class="new-dropdown-list absolute w-full left-0 top-full rounded-2xl px-4 bg-white"
        v-if="isOpen || options.length"
      >
        <div class="p-0 py-1 m-0" v-for="(item, index) in options" :key="index">
          <label class="new-label py-2">
            <input
              :key="item.id"
              type="radio"
              :value="item.value"
              v-model="model"
              onMouseDown="this.isChecked=this.checked;"
              onClick="this.checked=!this.isChecked;"
              @click="
                isOpen = false
                clearModel($event)
              "
            />
            <span class="new-checkbox-radio new-primary new-checkbox"></span>
            <span class="new-checkbox-radio-text fz-normal gray-color">
              {{ item.value }}
            </span>
          </label>
        </div>
        <div class="py-3 fz-normal gray-color" v-if="!options.length">
          <span
            class="new-checkbox-radio-text fz-normal gray-color text-opacity-70"
          >
            Nothing :((
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from '@/utils/click-outside'
export default {
  props: {
    /**
     * для динамического V-MODEL
     * @default: '' or null
     * @type: String or Array
     * @required: false
     */
    value: {
      required: false,
    },
    // Options
    options: {
      type: Array,
      required: false,
      default: [],
    },
    // Placeholder (title)
    placeholder: {
      type: String,
      required: false,
      default: 'Select option',
    },
    /**
     * Single or Multi
     * @default: false
     */
    multi: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
    },
    clearModel(event) {
      if (!event.target.checked) this.model = null
    },
    hide() {
      this.isOpen = false
    },
  },
  // mounted () {
  // 	this.popupItem = this.$el
  // },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  directives: {
    ClickOutside,
  },
}
</script>

<style lang="sass" scoped>
.new-label input
    z-index: 1
    width: 100%
    height: 100%
    cursor: pointer
.dropdown-enter,
.dropdown-leave-to
    opacity: 0
    transform: translateY(0px)
.dropdown-enter-to,
.dropdown-leave
    opacity: 1
    transform: translateY(6px)
.dropdown-enter-active,
.dropdown-leave-active
    transition: all 0.25s ease
</style>
